export function fixEncoding(str) {
  return str
    .replace(/Ã‡/g, "Ç")
    .replace(/Ã§/g, "ç")
    .replace(/Äž/g, "Ğ")
    .replace(/ÄŸ/g, "ğ")
    .replace(/Ä°/g, "İ")
    .replace(/Ä±/g, "ı")
    .replace(/Ã–/g, "Ö")
    .replace(/Ã¶/g, "ö")
    .replace(/Åž/g, "Ş")
    .replace(/ÅŸ/g, "ş")
    .replace(/Ãœ/g, "Ü")
    .replace(/Ã¼/g, "ü");
}
